import "./clima.css"

const Head = () => {
  return (
    <>
      <section className='head'>
        <div className='container flexSB paddingTB'>
          <div className='logo'>
            {/* <img src='../images/portal/LOGO.png' alt='Logo' /> */}
          </div>
          <div className='ad'>
         
          </div>
        </div>
      </section>
    </>
  )
}

export default Head
