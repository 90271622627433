import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { hero, popular, ppost } from "../../dummyData";
import Side from "../home/sideContent/side/Side";
import SinglePageSlider from "./slider/SinglePageSlider";
import "./singlepage.css";
import "../home/sideContent/side/side.css";
import "../home/mainContent/homes/style.css";

const SinglePage = () => {
  const { id } = useParams();
  const [heroItem, setHeroItem] = useState(null);
  const [popularItem, setPopularItem] = useState(null);
  const [ppostItem, setPpostItem] = useState(null);

  useEffect(() => {
    const item = hero.find((item) => item.id === parseInt(id));
    window.scrollTo(0, 0);
    if (item) {
      setHeroItem(item);
    }
  }, [id]);

  useEffect(() => {
    const item = popular.find((item) => item.id === parseInt(id));
    if (item) {
      setPopularItem(item);
    }
  }, [id]);

  useEffect(() => {
    const item = ppost.find((item) => item.id === parseInt(id)); // Busca en ppost
    if (item) {
      setPpostItem(item); // Establece ppostItem si encuentra una coincidencia
    }
  }, [id]);

  // Funciones para compartir en redes sociales
  const shareUrl = window.location.href;
  const title = heroItem ? heroItem.title : popularItem ? popularItem.title : ppostItem ? ppostItem.title : "";

  const shareOnTwitter = () => {
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(shareUrl)}`;
    window.open(twitterUrl, '_blank');
  };

  const shareOnFacebook = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
    window.open(facebookUrl, '_blank');
  };

  return (
    <>
      {heroItem || popularItem || ppostItem ? ( // Incluye ppostItem en la condición
        <main>
          <SinglePageSlider />
          <div className="container">
            <section className="mainContent details">
              <h1 className="title">
                {/* Ajusta aquí para mostrar el título basado en qué item está presente */}
                {heroItem ? heroItem.title : popularItem ? popularItem.title : ppostItem.title}
              </h1>
              {/* Componentes adicionales y lógica de renderización como antes */}
              <div className="social"> 
              <div className='socBox'>   
              <i className="fab fa-facebook-f"></i>                    
                <button style={{ background: "#3b5998", fontWeight: 'bold', fontSize: '1rem', border: 'none', color: "white"  }} onClick={shareOnFacebook}>COMPARTIR</button>
                </div>
                <div className='socBox'>
          <i className='fab fa-x'></i>
          <button style={{ background: "black", fontWeight: 'bold', fontSize: '1rem', border: 'none', color: "white"  }} onClick={shareOnTwitter} children="bg-black" >COMPARTIR</button>
        </div>
              </div>
  
              <div className="desctop">
                {/* Descripción basada en el item actual */}
                {(heroItem ? heroItem.desc : popularItem ? popularItem.desc : ppostItem.desc).map((val, index) => (
                  <p key={index}>{val.para1}</p>
                ))}
              </div>
              <img src={heroItem ? heroItem.cover : popularItem ? popularItem.cover : ppostItem.cover} alt="" />
              {(heroItem ? heroItem.desc : popularItem ? popularItem.desc : ppostItem.desc).map((val, index) => (
                <p key={index}>{val.para3}</p>
              ))}
  
              <div className="descbot">
                {(heroItem ? heroItem.details : popularItem ? popularItem.details : ppostItem.details).map((data, index) => (
                  <div key={index}>
                    <h1>{data.title}</h1>
                    <p>{data.para1}</p>
                  </div>
                ))}
              </div>
  
              <div className="quote">
                {(heroItem ? heroItem.details : popularItem ? popularItem.details : ppostItem.details).map(
                  (data) => (
                    <p>{data.quote}</p>
                  )
                )}
              </div>
  
              <div className="desctop">
                {(heroItem ? heroItem.details : popularItem ? popularItem.details : ppostItem.details).map(
                  (data) => {
                    return (
                      <>
                        <p>{data.para2}</p>
                        <p>{data.para3}</p>
                      </>
                    );
                  }
                )}
                <section className="Publicidad-Section">
                  <img src="/images/portal/TODOS.jpeg" alt="Imagen de trabajo" className="Publicidad-Gobierno-Todos" />
                </section>
              </div>
            </section>
            <section className="sideContent">
              <Side />
            </section>
          </div>
        </main>
      ) : (
        <h1>not found</h1>
      )}
    </>
  );
  
};

export default SinglePage;
