import React from "react"
import "./side.css"
import Slider from "react-slick"
import Heading from "../../../common/heading/Heading"
import { gallery } from "../../../../dummyData"
import Tpost from "../Tpost/Tpost"
import SocialMedia from "../social/SocialMedia"
import Clima from "../../../common/header/Clima"
import { useLocation } from "react-router-dom";

//const allCat = [...new Set(popular.map((curEle) => curEle.category))]
//console.log(allCat)

const Side = () => {

  const location = useLocation(); // Obtener la ruta actual

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    }

    const category = ["Cultura", "Viajes", "Deportes", "Diversion", "Salud", "Moda", "Negocios", "Tecnologia"]
    return (
        <>
            <Heading title='Conectate' />
            <SocialMedia />

            <Heading title='Subscribite' />

            {/* <section className='subscribe'>
        <h1 className='title'>Subscribite</h1>
        <form action=''>
          <input type='email' placeholder='Direccion de Email...' />
          <button>
            <i className='fa fa-paper-plane'></i> ENVIAR
          </button>
        </form>
      </section> */}


            <section className='banner'>
                <a href="https://wa.me/+543795160379" target="_blank" rel="noreferrer">
                    <img src="/images/lucas.png" alt="Imagen de trabajo" className="Publicidad-Gobierno" />
                </a>
            </section>

            <section className='banner'>
                <a href="https://www.rymcontable.com.ar" target="_blank" rel="noreferrer">
                    <img src="/images/rym-logo.png" alt="Imagen de trabajo" className="Publicidad-Gobierno" />
                </a>
            </section>

            <section className='banner'>
                <img src="/images/portal/TRABAJO.jpeg" alt="Imagen de trabajo" className="Publicidad-Gobierno" />
            </section>

            <section className='banner'>
                <a href="https://www.instagram.com/167fitnessclub/" target="_blank" rel="noreferrer">
                    <img src="/images/167-cuadrado.jpeg" alt="Imagen de trabajo" className="Publicidad-Gobierno" id="gym" />
                </a>
            </section>

          {/* Condicionar la renderización del banner del clima */}
          {!location.pathname.startsWith('/SinglePage') && (
                <>
                <section className='banner'>
                    <Clima />
                </section>
            <Tpost />
            </>
            )}

            {/* 
      <section className='catgorys'>
        <Heading title='Categorias' />
        <div className='items'>{allCat}</div>
        {category.map((val) => {
          return (
            <div className='category category1'>
              <span>{val}</span>
            </div>
          )
        })}
      </section> */}

            {/* <section className='gallery'>
        <Heading title='Galeria' />
        <Slider {...settings}>
          {gallery.map((val) => {
            return (
              <div className='img'>
                <img src={val.cover} alt='' />
              </div>
            )
          })}
        </Slider>
      </section> */}
        </>
    )
}

export default Side
