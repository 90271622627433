import React from "react";
import Slider from "react-slick";
import { ppost } from "../../../../dummyData";
import Heading from "../../../common/heading/Heading";
import { Link } from "react-router-dom";
import "./ppost.css";

const Ppost = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2, // Este valor es correcto si tu diseño puede mostrar 2 slides a la vez.
    slidesToScroll: 1,
  };

  // Dividir el array 'ppost' en dos: los primeros 2 elementos y los últimos 2 elementos.
  const destacadas = ppost.slice(0, 2); // Primeros 2 elementos
  const corrientes = ppost.slice(2, 4); // Últimos 2 elementos

  return (
    <>
      <section className='popularPost'>
        <Heading title='Destacadas' />
        <div className='content'>
          <Slider {...settings}>
            {destacadas.map((val) => (
              <div className='items' key={val.id}>
                <Link to={`/SinglePage/${val.id}`}>
                  <div className='box shadow'>
                    <div className='images'>
                      <div className='img'>
                        <img src={val.cover} alt='' className="ppost-image"/>
                      </div>
                      <div className='category category1'>
                        <span>{val.category}</span>
                      </div>
                    </div>
                    <div className='text'>
                      <h1 className='title'>{val.title.slice(0, 40)}...</h1>
                      <div className='date'>
                        <i className='fas fa-calendar-days'></i>
                        <label>{val.date}</label>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
        </div>
      </section>
      <section className='popularPost'>
        <Heading title='Novedades' />
        <div className='content'>
          <Slider {...settings}>
            {corrientes.map((val) => (
              <div className='items' key={val.id}>
                <Link to={`/SinglePage/${val.id}`}>
                  <div className='box shadow'>
                    <div className='images'>
                      <div className='img'>
                        <img src={val.cover} alt='' className="ppost-image"/>
                      </div>
                      <div className='category category1'>
                        <span>{val.category}</span>
                      </div>
                    </div>
                    <div className='text'>
                      <h1 className='title'>{val.title.slice(0, 40)}...</h1>
                      <div className='date'>
                        <i className='fas fa-calendar-days'></i>
                        <label>{val.date}</label>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    </>
  );
};

export default Ppost;
