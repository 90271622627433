export const hero = [
  {
    id: 1,
    cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/11/foto-1-gentileza.jpg',
    category: "POLÍTICA",
    title: "Gobernadores radicales llevan reclamos a Milei",
    time: "6 Nov - 2024",
    desc: [
      {
        para1: "06/11/2024 - El presidente Javier Milei se reunirá hoy con gobernadores radicales, incluyendo a Gustavo Valdés (Corrientes), Alfredo Cornejo (Mendoza), Leandro Zdero (Chaco) y Maximiliano Pullaro (Santa Fe), para discutir temas como la financiación de obras, presupuestos provinciales y financiamiento para universidades nacionales."
      },
      {
        para2: "Valdés señaló que asistirán a la reunión por invitación y añadió: «Considero que Alfonsín era un demócrata, para nada creo que fuera un golpista. Esas declaraciones tienen más que ver con el carácter del Presidente que con lo que Alfonsín hizo en toda su vida»."
      },
      {
        para3: "El Gobernador también adelantó que el proyecto de Presupuesto 2025 de Corrientes, de 2 billones 340 mil millones de pesos, ya fue remitido a la Cámara de Diputados provincial y que están trabajando con el Gobierno nacional en aspectos presupuestarios."
      }
    ],
    details: [
      {
        title: "Reunión del Norte Grande"
      },
      {
        para1: "Valdés confirmó que asistirá a la reunión de gobernadores del Norte Grande el viernes 8 en Santiago del Estero. Señaló la importancia de apoyar a los empresarios y trabajar con el BID en financiamiento para el sector privado, destacando que se busca potenciar proyectos de Participación Público Privada (PPP)."
      },
      {
        para2: "«El BID Invest no financia al Estado, sino a empresas privadas interesadas en proyectos grandes. Estamos invitando a las cámaras empresariales correntinas para explorar oportunidades de inversión y desarrollo», concluyó Valdés."
      }
    ]
  }
  ,
  {
    id: 2,
    cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/11/Sin-titulo-1.jpg',
    category: "POLÍTICA",
    title: "Valdés dijo que las críticas de Milei hacia la UCR y Alfonsín se deben a su «frescura»",
    time: "6 Nov - 2024",
    desc: [
      {
        para1: "06/11/2024 - Tras la reunión que se desarrolló este martes en la Casa Rosada, entre el presidente Javier Milei y los gobernadores radicales, el mandatario correntino, Gustavo Valdés se refirió en diálogo con la prensa sobre los temas abordados."
      },
      {
        para2: "Hablamos de la marcha de la economía nacional, del equilibrio fiscal, de las posibilidades presupuestarias. Hablamos de infraestructura, de la realidad de cada una de las provincias. En líneas generales la reunión fue muy buena, duró más de dos horas”, mencionó el correntino, quien participó del encuentro junto a sus pares de Santa Fe, Maximiliano Pullaro; de Mendoza, Alfredo Cornejo; de Chaco, Leandro Zdero y de Jujuy, Carlos Sadir."
      },
      {
        para3: "En materia presupuestaria, lo que hablamos es de algo que es natural, que es el equilibrio fiscal, que ocurre en nuestras provincias que están bien administradas por nuestros gobernadores radicales más allá de las herencias que tuvimos del pasado”, destacó Valdés, para luego sumar que dialogaron sobre “cómo vamos a hacer para invertir y motorizar la economía que es lo que necesitamos los argentinos para salir de esta difícil situación que nos dejó el kirchnerismo”."
      }
    ],
    details: [
      {
        title: ""
      },
      {
        para1: "Por otra parte, el correntino se refirió a las duras críticas que realizó Milei hacia el radicalismo en general y a uno de sus máximos referentes históricos, Raúl Alfonsín, a quien trató de “golpista” por su rol en 2001. “Es la frescura que tiene Milei”, dijo Valdés para justificar lo dicho por el Presidente."
      },
      {
        quote: "Se conversó de que no estábamos de acuerdo con ese punto de vista, pero es más del pasado; nosotros estuvimos hablando del futuro fundamentalmente, porque venimos a construir el futuro juntos y a pensar hacia adelante."
      },
      {
        para2: ""
      },
      {
        para3: ""
      }
    ]
  },
  {
    id: 3,
    cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/11/9TWbN1IXN_1256x620__1.jpg',
    category: "JUSTICIA",
    title: "Los padres de Loan hicieron un llamativo pedido de detención",
    time: "6 Nov - 2024",
    desc: [
      {
        para1: "06/11/2024 - Este martes, los padres de Loan solicitaron la detención inmediata de Gustavo Vera, presidente de la Fundación Alameda, basándose en una presunta asociación ilícita, encubrimiento y falso testimonio."
      },
      {
        para2: "Juan Pablo Gallego, abogado de la familia del niño de 5 años, confirmó la denuncia realizada por José Peña y María Noguera ante el fiscal federal de Goya, Mariano de Guzmán."
      },
      {
        para3: "Gallego explicó que algunas de las conductas atribuidas a Vera se ven «flagrantemente» respaldadas por la grabación de su declaración en una de las audiencias de la causa que investiga la desaparición de Loan. Agregó que el fiscal podría ordenar la detención de Vera si no se disponen antes otras diligencias en la investigación."
      }
    ],
    details: [
      {
        title: ""
      },
      {
        para1: "La denuncia describe un entramado, supuestamente liderado por Gustavo Vera, con una red de personas que tendrían distintos roles para encubrir la desaparición de Loan y obstaculizar la investigación."
      },
      {
        quote: "Se resalta que todo el proceso de obstrucción a la Justicia se efectuó a través de declaraciones falsas y maniobras de distracción."
      },
      {
        para2: ""
      },
      {
        para3: ""
      }
    ]
  }
  ,
  {
    id: 4,
    cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/11/Regatas-sede-del-Cuadrangular.jpg',
    category: "DEPORTES",
    title: "Presentan el Cuadrangular Final y largan las semis de la “Fabián Sosa”",
    time: "6 Nov - 2024",
    desc: [
      {
        para1: "06/11/2024 - Este miércoles a las 11, en la sede de la Asociación de Básquetbol de Corrientes (ABCC) ubicada en 9 de Julio 1536, se llevará a cabo la presentación oficial del Cuadrangular Final que disputará la Copa “Municipalidad de la Ciudad de Corrientes”."
      },
      {
        para2: "En la conferencia, el titular de la ABCC, Alfredo Wenk, estará acompañado por entrenadores y capitanes de los cuatro clubes finalistas: Córdoba, Juventus, Colón y Regatas, para detallar aspectos clave del evento."
      },
      {
        para3: "El torneo que consagrará al campeón de la temporada comenzará este jueves en el estadio “José Jorge Contte” del Club de Regatas Corrientes, con las semifinales al mejor de tres partidos. En la primera jornada, Juventus enfrentará a Colón a las 21, y posteriormente, a las 22.30, Córdoba se medirá con Regatas."
      }
    ],
    details: [
      {
        title: ""
      },
      {
        para1: "Además, este miércoles se disputarán las primeras semifinales de la Copa “Fabián Sosa” en el estadio Marcelino “Cora” Ojeda del Club Pingüinos. Los encuentros están programados para las 21 entre Don Bosco y Malvinas 1536 Viviendas, y a las 22.30, El Tala jugará contra Sportivo Corrientes."
      },
      {
        para2: "Por su parte, la Copa “Jorge Desagastizábal” arrancará sus semifinales el viernes en el estadio de Juventus. A las 21 jugarán Alvear y Pingüinos, y a las 22.30 será el turno de San Martín y Hércules."
      },
      {
        para3: "El evento promete emociones fuertes en cada uno de los torneos, destacando la dedicación de los equipos y el esfuerzo de la organización para brindar un espectáculo de calidad a los aficionados del básquet en Corrientes."
      }
    ]
  }
  ,
]
export const world = [
  {
    id: 5,
    cover: "https://www.lanacion.com.ar/resizer/v2/la-devaluacion-trajo-fuertes-costos-que-el-equipo-NTIFKCFGXRCGPNQBAVZKGCEZ3Y.JPG?auth=98785e2b145e8eef125b3919f7e51fb1a3c1486a797cf16f150738ed9f1b12cf&width=880&height=586&quality=70&smart=false",
    category: "ECONOMIA",
    title: "Repartir hasta lo ajeno para pagar la elevada factura de la devaluación oficial",
    authorName: "Salman",
    time: "5 Jun - 2022",
    desc: [
      {
        para1: "You audience. Least, the recently his repeat the this avarice for the have and was on would before the concise bedding were hall politely name be regretting have of even five of it the his are there again. Word seven designer far lady problem will have work with you to fully understand your business to achieve.",
      },
      {
        para2: "We work with clients big and small across a range of sectors and we utilize all forms of media to get your name out there in a way that’s right for you. We believe that analysis of your company and your customers is key in responding effectively to your promotional needs and we will work with you.",
      },
      {
        para3: "We have a number of different teams within our agency that specialise in different areas of business so you can be sure that you won’t receive a generic service and although we can’t boast years and years of service we can ensure you that is a good thing in this industry.",
      },
    ],
    details: [
      {
        title: "Starting a new company is easy",
      },
      { para1: "Our teams are up to date with the latest technologies, media trends and are keen to prove themselves in this industry and that’s what you want from an advertising agency, not someone who is relying on the same way of doing things that worked 10 years, 5 years or even a year ago." },
      {
        quote: "Scarfs, still not this no with explains it me and option on the any options roasted when I and state can that an don't subjective of has his take on and in from royal everything took raising our have behind success you the mechanic.",
      },
      {
        para2: "And, higher by agency; In from their in and we spirit, through merely small him sounded a all now, with that put gift white highly geared that was left back as of or logged important. A over have the large try understanding the believe. Perfected been viewer. Shreds early willingly safely what passion the.",
      },
      {
        para3: "In an ideal world this website wouldn’t exist, a client would acknowledge the importance of having web copy before the design starts. Needless to say it’s very important, content is king and people are beginning to understand that. However, back over in reality some project schedules and budgets don’t allow for web copy to be written before the design phase, this is sad but true.",
      },
    ],
  },
  {
    id: 6,
    cover: "../images/world/world2.jpg",
    category: "",
    title: "Renewablee91 ener553gy dead industry waits for Policya ",
    authorName: "Salmana",
    time: "5 Jun - 2022",
    desct: "If rather, him the would treble-range wild any long of policy waved preparations disguised first initial that commitment had my in for spirits gradual solitary I for his absolutely with it and I to unable follow the to as every it the control and a of thinks the what's help days, with and paint, series at window and could we've offer, hard with king either written dry be are of princesses. We’re here to help, we’ve written examples of web copy for over 40 industries for you to use at concept phase of your projects to bring a little life and realism to your designs and help you think about who and what you are designing for. We want clients and designers alike to think about their design and how it will work with the web copy, we want you think about how numbers, symbols and bullet points will look. Sure, it's easier to just run down the forms and fill the fields with garbage in order to plow through them quickly. But that's not real. That's not what your customers are going to do. Is it really smart to take a shortcut when customers are forced to take the long road? When you just enter fake copy in rapid-fire fashion, you don't know what it really feels like to fill out that form. Do as your customers do and you'll understand them better. When you understand them better, and feel what they feel, you'll build a better interface.",
  },
  {
    id: 7,
    cover: "../images/world/world3.jpg",
    category: "",
    title: "Mount Etna erupts fashion in nice12 looking pictures ",
    authorName: "Salman",
    time: "5 Jun - 2022",
  },
  {
    id: 8,
    cover: "../images/world/world2.jpg",
    category: "",
    title: "China asks Canada to add their full plan ",
    authorName: "Salman",
    time: "5 Jun - 2022",
  },
  {
    id: 9,
    cover: "../images/world/world2.jpg",
    category: "",
    title: "Serena secures comeback win against Ivanovic",
    authorName: "Salman",
    time: "5 Jun - 2022",
  },
]
export const popular = [
  {
    id: 10,
    cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/11/ypf-academia-proveedores-2.jpg',
    category: "ECONOMÍA",
    title: "YPF lanzó la Academia de Proveedores",
    date: "6 Nov - 2024",
    desc: [
      {
        para1: "06/11/2024 - YPF realizó el lanzamiento de la “Academia de Proveedores”, un programa de formación en el mercado que busca desarrollar a sus proveedores mediante el valor del conocimiento."
      },
      {
        para2: "“En YPF tenemos unos 5.000 proveedores y consumimos más de 100.000 productos o servicios. Lo interesante es que más del 60% de nuestras tareas están realizadas por proveedores. Por eso, debemos fortalecer este vínculo y crecer juntos”, señaló Walter Actis, vicepresidente de Supply Chain y Servicios de YPF."
      },
      {
        para3: "La Academia está diseñada para que los proveedores se capaciten en cuatro bloques: vinculación, que promueve relaciones eficientes; Masterclass, para nivelar empresas en temas clave; programas de formación intensiva sobre temas específicos; y un pilar experto, que brinda formación personalizada en áreas estratégicas con acompañamiento de YPF."
      }
    ],
    details: [
      {
        title: ""
      },
      {
        para1: "El programa está dirigido a empresas interesadas en ser proveedores de YPF, miembros de cámaras, entidades, y proveedores estratégicos, dependiendo del pilar en que participen."
      },
      {
        para2: "Esta iniciativa de la Gerencia de Desarrollo de Proveedores requiere compromiso para cumplir con estándares de calidad y seguridad de YPF, basados en el modelo de Excelencia Operacional y el Programa de Integridad de la compañía."
      },
      {
        para3: "Con este lanzamiento, YPF refuerza su compromiso de mejorar la eficiencia y competitividad del sector, buscando alcanzar la meta de convertir a Argentina en un exportador de energía por 30.000 millones de dólares para el 2030."
      }
    ]
  }
  ,
  {
    id: 11,
    cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/11/asdasdasdadsafeww.jpg',
    category: "POLÍTICA",
    title: "De qué hablaron Milei y los gobernadores radicales durante dos horas",
    date: "6 Nov - 2024",
    desc: [
      {
        para1: "06/11/2024 - Este martes, el presidente Javier Milei se reunió en Casa Rosada con Gustavo Valdés y otros gobernadores radicales para discutir el Presupuesto 2025 y temas adicionales, en un encuentro de más de dos horas. Estuvieron presentes Alfredo Cornejo (Mendoza), Maximiliano Pullaro (Santa Fe), Carlos Sadir (Jujuy) y Leandro Zdero (Chaco)."
      },
      {
        para2: "Milei buscó abordar los reclamos y responder las inquietudes de los gobernadores, quienes calificaron el diálogo como positivo, destacando la «voluntad política» del presidente para enfrentar los problemas."
      },
      {
        para3: "Entre los temas discutidos se incluyeron la deuda nacional con las provincias, la privatización de Aerolíneas Argentinas, infraestructura y obras. Los gobernadores solicitaron licitaciones para la hidrovía, aceptación de préstamos provinciales, y la llegada de aerolíneas privadas."
      }
    ],
    details: [
      {
        title: ""
      },
      {
        para1: "El presidente mencionó que ya se están viendo signos de recuperación en la economía, con mejoras graduales en los salarios y una baja en la inflación. También destacó la disminución del riesgo país como señal positiva."
      },
      {
        para2: "Durante la reunión también surgió el tema de las críticas de Milei hacia Ricardo Alfonsín, generando un momento incómodo, aunque los gobernadores se mostraron conformes con la explicación del presidente."
      },
      {
        para3: "Otro tema de preocupación fue la posible privatización de Aerolíneas Argentinas, debate sensible para los gremios y la Casa Rosada. Los mandatarios insistieron en la necesidad de financiamiento para mejorar infraestructuras, incluyendo ferrocarriles y rutas."
      },
      {
        quote: "Los gobernadores elogiaron la disposición de Milei, esperando que su «voluntad política» se refleje en acciones concretas para el país."
      }
    ]
  }
  ,
  {
    id: 12,
    cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/11/estado_laico.jpg',
    category: "SOCIEDAD",
    title: "Cómo influye la religión en la política y sociedad: 1º Congreso en Corrientes sobre laicismo y secularización",
    date: "6 Nov - 2024",
    desc: [
      {
        para1: "06/11/2024 - Los próximos 13, 14 y 15 de noviembre se llevará a cabo en Corrientes el Primer Congreso de Laicismo y Secularización, organizado por el Instituto Laico de Estudios Contemporáneos Argentina (ILEC), con el fin de promover el libre pensamiento y la tolerancia en la sociedad."
      },
      {
        para2: "El congreso abordará la influencia del pensamiento religioso en la política, ciencia, arte y sociedad en general, con expositores de sectores públicos y privados, en tres sedes diferentes. La entrada es libre y gratuita, abierta a todo público."
      }
    ],
    details: [
      {
        title: "Agenda del Congreso"
      },
      {
        para1: "El miércoles 13 de noviembre a las 19:00 hs, en el aula magna de la Facultad de Derecho de la UNNE, los expositores serán Mario Villegas (decano de la facultad), Diana Rubianes (Secretaria Relatora de Fiscalía General) y Alejandro Chaín (Ministro del Superior Tribunal de Justicia de Corrientes)."
      },
      {
        para2: "El jueves 14 de noviembre a las 18:00 hs, en el Palacio Legislativo de Corrientes, expondrán Lucas Sisul Larroza (cirujano cardiovascular), Giancarlo Perversi (presidente de la sociedad Dante Alighieri), 'Thor' Scheling (Club de la Libertad) y Enrique Galeana (historiador de Corrientes)."
      },
      {
        para3: "El viernes 15 de noviembre a las 18:00 hs, en el aula magna de la Universidad de la Cuenca del Plata, participarán Cecilia Colautti (médica), Pedro Braillard Poccard (Vicegobernador de Corrientes), Alida Pasian (arquitecta) y Diógenes González (senador provincial). Darío Hernández, presidente del ILEC Argentina, y Francisco Mazzaro, presidente del ILEC Corrientes, darán las palabras finales."
      }
    ]
  }
  ,
  {
    id: 13,
    cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/11/Ituzaingo-parque-industrial-f1.jpg',
    category: "ECONOMÍA",
    title: "Varias empresas buscan radicarse en el Parque Industrial de Ituzaingó",
    date: "6 Nov - 2024",
    desc: [
      {
        para1: "06/11/2024 - Con 529 hectáreas destinadas al desarrollo forestoindustrial, el Parque Industrial de Ituzaingó ya cuenta con varias empresas establecidas y muchas otras avanzan con los estudios de impacto ambiental necesarios para operar en este punto estratégico del río Paraná."
      },
      {
        para2: "El intendente de Ituzaingó, Juan Pablo Valdés, comentó que esperan concretar el desarrollo industrial de la región, destacando que el parque y su puerto permitirán exportar los productos correntinos al mundo."
      },
      {
        para3: "En el marco del Concurso de Pesca del Surubí, el intendente confirmó que el parque industrial se encuentra en una fase de obras financiadas por el BID y una vez completada la infraestructura con certificación ambiental, se habilitará la instalación de nuevas empresas."
      }
    ],
    details: [
      {
        title: "Proyectos y expectativas"
      },
      {
        para1: "Valdés mencionó que hay solicitudes para instalar fábricas de diversos rubros, como plantas de alpargatas, vidrio, y pintura, así como silos arroceros, generando grandes expectativas en la comunidad."
      },
      {
        para2: "La conclusión de las obras está prevista para diciembre o enero, lo cual coincide con la reapertura del paso fronterizo con Ayolas (Paraguay), aumentando el potencial productivo e industrial de la región."
      },
      {
        title: "Exportaciones y potencial económico"
      },
      {
        para3: "El Parque Industrial fortalecerá el crecimiento exportador de Corrientes. Según el Instituto Provincial de Estadística y Ciencia de Datos de Corrientes (IPECD), las exportaciones de la provincia en el último año se distribuyeron en productos primarios (48,1%), manufacturas industriales (28,6%), y agropecuarias (23,2%). Los principales productos son arroz, madera, y yerba mate."
      }
    ]
  }
  ,
  {
    id: 14,
    cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/11/descarga-7.jpeg',
    category: "ECONOMÍA",
    title: "Cybermonday comenzó con gran volumen de ventas en las primeras horas",
    date: "5 Nov - 2024",
    desc: [
      {
        para1: "5/11/2024 - El evento de descuentos y promociones CyberMonday, organizado por la Cámara Argentina de Comercio Electrónico (Cace), registró un alto volumen de ventas en sus primeras 12 horas, según empresas participantes."
      },
      {
        para2: "Pasado el mediodía, Tiendanube reportó más de 7.500 millones de pesos en facturación, con un ticket promedio de 83.840 pesos. Con 90 mil transacciones y 261 productos vendidos por minuto, la tarjeta de crédito dominó las compras con un 78% de uso."
      },
      {
        para3: "Durante los próximos tres días, tiendas de Argentina ofrecerán precios especiales en sus plataformas online. La venta máxima hasta el momento ocurrió a las 10:19 con 1.397 productos vendidos por minuto."
      }
    ],
    details: [
      {
        title: "Tendencias y estadísticas del evento"
      },
      {
        para1: "Las categorías más populares incluyen Indumentaria (48,2% de participación), Salud y Belleza (8,5%) y Deco y Hogar (5,2%). CABA y GBA concentraron el 50,5% de las ventas, seguidos por Interior de Buenos Aires con 14%, Córdoba 8,8%, y Santa Fe 7,3%."
      },
      {
        para2: "Facturante informó un ticket promedio de 69.000 pesos, 173% más alto que en 2023. Lorena Comino, CEO de Facturante, destacó un crecimiento interanual del 213% en facturación, con un 14% más de comprobantes emitidos."
      },
      {
        title: "Participación de tiendas bancarias y e-commerce"
      },
      {
        para3: "Las tiendas bancarias, como Tienda BNA y Macro Premia, ofrecieron cuotas sin interés, cashback, y envíos gratis. avenida+ estimó un ticket promedio de 450 mil pesos en los primeros tres días, con ventas destacadas de productos como freidoras y aspiradoras robot, heladeras, y productos de gaming."
      },
      {
        para4: "Familia Bercomat, líder en materiales de construcción, proyecta un crecimiento del 400% en ventas digitales. Gabriel Paredes, jefe comercial de la empresa, señaló una preparación anticipada y optimización de inventario para brindar una mejor experiencia durante el evento."
      },
      {
        para5: "Cace reportó un crecimiento del 248% en facturación interanual en e-commerce en la primera mitad del año, alcanzando un total de 8.555.918 millones de pesos."
      }
    ]
  }
  ,
  {
    id: 15,
    cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/11/descarga-1-2.jpeg',
    category: "SOCIEDAD",
    title: "Día del Empleado Municipal: Cómo funcionarán las dependencias comunales",
    date: "5 Nov - 2024",
    desc: [
      {
        para1: "5/11/2024 - En conmemoración del Día del Empleado Municipal el 8 de noviembre, la Municipalidad de Corrientes informó que algunas dependencias estarán cerradas, aunque se garantizará el servicio de recolección de residuos y habrá guardias en áreas operativas."
      },
      {
        para2: "Según la Ordenanza Nº1944, el 8 de noviembre es no laborable para empleados municipales. Por ello, la Agencia Correntina de Recaudación (Acor), el Centro Emisor de Licencias, el Tribunal de Faltas y otras oficinas administrativas retomarán actividades el lunes."
      },
      {
        para3: "Las Salas de Atención Primaria de la Salud (Saps) y puntos Sube no atenderán, ni habrá cobro de estacionamiento medido en la ciudad."
      }
    ],
    details: [
      {
        title: "Servicios esenciales y guardias"
      },
      {
        para1: "La Secretaría de Ambiente informó que la recolección de residuos funcionará normalmente en todas las zonas, incluyendo el servicio de reciclaje en barrios específicos."
      },
      {
        para2: "La Dirección General de Defunciones mantendrá guardia de 7 a 19 en la oficina de calle 25 de Mayo 1178 para trámites urgentes. Los cementerios San Juan Bautista y Laguna Brava estarán abiertos de 7 a 17."
      },
      {
        title: "Funcionamiento de mercados y ferias"
      },
      {
        para3: "Los mercados de Abasto y Productos Frescos permanecerán cerrados, pero los Paseos de Compras abrirán de 9 a 21. Las Ferias de la Ciudad operarán normalmente el viernes en varias ubicaciones, incluyendo la plaza República del Perú y la plazoleta Los Inmigrantes."
      },
      {
        title: "Turismo y áreas operativas"
      },
      {
        para4: "Los Centros de Informes Turísticos tendrán guardias: Terminal (7 a 13 y 16 a 20), Plaza Vera (8 a 20), y Paseo del Puerto (13:30 a 21:30). Áreas de Tránsito, Guardia Urbana, y Transporte operarán con personal de guardia."
      }
    ]
  }
  ,
  {
    id: 16,
    cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/11/whatsapp_image_2024-11-05_at_11_16_14_1_.jpeg',
    category: "POLÍTICA",
    title: "Tassano recibió al cónsul de Paraguay: consolidaron lazos y trazaron agenda",
    date: "5 Nov - 2024",
    desc: [
      {
        para1: "5/11/2024 - En el Palacio Municipal, el intendente Eduardo Tassano recibió al cónsul de Paraguay, Fabio López Riveros, en una reunión que fortaleció los lazos históricos y presentes entre Corrientes y el país vecino."
      },
      {
        para2: "López Riveros, cuya jurisdicción abarca Corrientes y Chaco con sede en Resistencia, presentó oficialmente sus saludos protocolares en el Municipio correntino."
      },
      {
        para3: "Durante la reunión, Tassano y el cónsul discutieron sobre iniciativas para fortalecer la integración regional y compartieron presentes en señal de amistad."
      }
    ],
    details: [
      {
        title: "Consolidando lazos y creando puentes"
      },
      {
        para1: "Tassano valoró la visita de un país querido como Paraguay y resaltó la importancia de que Corrientes se consolide como un referente del norte argentino para articular acciones turísticas y diplomáticas."
      },
      {
        title: "Hermandad y agenda común"
      },
      {
        para2: "El cónsul López Riveros agradeció el recibimiento y señaló que ambos trazaron una agenda común para promover la integración mediante el comercio, cultura y turismo entre ambos países."
      }
    ]
  }
  ,
  {
    id: 17,
    cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/11/o_1711625620.jpg',
    category: "JUSTICIA",
    title: "Poder Judicial de Corrientes definió el periodo de feria",
    date: "5 Nov - 2024",
    desc: [
      {
        para1: "5/11/2024 - El Poder Judicial de Corrientes estableció el periodo de feria judicial de verano mediante el Acuerdo de Ministros N°32/24."
      },
      {
        para2: "El último día laborable será el lunes 23 de diciembre de 2024, ya que el martes 24 se decretó feriado judicial."
      },
      {
        para3: "La feria se extenderá desde el jueves 26 de diciembre de 2024 hasta el viernes 31 de enero de 2025."
      }
    ],
    details: [
      {
        title: "Feriados judiciales adicionales"
      },
      {
        para1: "Se declaró feriado judicial los días 24 y 31 de diciembre por las festividades de Nochebuena y Fin de Año, de modo que el personal judicial podrá disfrutar de estos días en familia."
      }
    ]
  }
  ,


  // HASTA ACA SE VEN DE ENTRADA, LAS DE ABAJO HAY QUE MOVER EL SLIDER PARA VERLAS.
  {
    id: 18,
    category: "POLICIALES",
    title: "Libres: recuperan el dinero robado de un comercio por Ruta 117",
    date: "18. Septiembre 2023",
    comments: 0,
    cover: "https://www.diarioepoca.com/content/bucket/5/1320445w762h739c.jpg.webp",
    desc: [
      {
        para1: "Efectivos de la Comisaría Primera de Paso de los Libres realizaron ayer un allanamiento y lograron recuperar la totalidad del botín en efectivo que delincuentes robaron de un parador ubicado a la vera de la Ruta Nacional N°117. Hay dos hombres detenidos.",
      },
      {
        para2: "",
      },
      {
        para3: "",
      },
    ],
    details: [
      {
        title: "",
      },
      { para1: "Según la información proporcionada por la Policía, el hecho ocurrió días atrás y durante el fin de semana se demoró a los dos maleantes que habían perpetrado el ilícito, pero faltaba ubicar el lugar donde ocultaban el dinero. Los trabajos consistieron en reunir toda la información posible, que también constó de imágenes de cámaras de seguridad de la zona y con lo que pudieron identificar a los delincuentes." },
      {
        quote: "Por ello, el sábado elementos de la sección Investigaciones de la Comisaría Primera, con orden de allanamiento de la Unidad Fiscal de Investigaciones de Paso de los Libres, requisaron una finca ubicada en la quinta sección Palmar, Chacra 250, donde finalmente lograron dar con la totalidad del dinero en efectivo, alrededor de $450.000 que había sido robado del parador Don Tolo",
      },
      {
        para2: "Tanto los dos detenidos como el dinero fueron puestos a disposición de la Justicia y la investigación continúa para descartar la participación de más personas en el hecho.",
      },
      {
        para3: "",
      },
    ],
  },
  {
    id: 19,
    category: "TECNOLOGIA",
    title: "Cómo la Inteligencia Artificial está cambiando la forma de buscar vida extraterrestre",
    date: "17. Septiembre 2023",
    comments: 0,
    cover: "https://www.infobae.com/new-resizer/MQrbURODdl9ufluwK5Mch7FjW4c=/992x558/filters:format(webp):quality(85)/cloudfront-us-east-1.images.arcpublishing.com/infobae/ELC3ESJ3AZHKNJETOU2YQT4I7Y.png",
    desc: [
      {
        para1: "Tras el lanzamiento de dos telescopios con el objetivo de encontrar planetas habitables, y con un total de 5.496 exoplanetas confirmados y 9.820 más en espera de este aval, la carrera por caracterizar estos mundos distantes y evaluar su habitabilidad impulsa a la ciencia hacia nuevos límites",
      },
      {
        para2: ".",
      },
      {
        para3: "",
      },
    ],
    details: [
      {
        title: "",
      },
      { para1: "En los últimos dos años y medio, se han enviado al espacio dos telescopios de próxima generación: el Espacial James Webb (JWST) de la NASA y el Observatorio Euclid de la ESA. Antes de que termine la década, se les unirán el Telescopio Espacial Romano Nancy Grace (RST), el Espectrofotómetro para la Historia del Universo, la Época de la Reionización y el Explorador de Hielos (SPHEREx) de la NASA, y el Tránsito y Oscilaciones Planetarias de la ESA (PLATO), entre otros." },
      {
        quote: "Estos observatorios se basarán en óptica e instrumentos avanzados para ayudar en la búsqueda y caracterización de exoplanetas con el objetivo final de encontrar planetas habitables. Junto con las misiones aún operativas, estos observatorios recopilarán volúmenes masivos de datos espectroscópicos de alta resolución.",
      },
      {
        para2: "lasificar esta información requerirá técnicas de aprendizaje automático de vanguardia para buscar indicios de vida y procesos biológicos (también conocidos como biofirmas). En un artículo reciente, un equipo de científicos del Instituto de Teoría Fundamental de la Universidad de Florida (UF-IFL) recomendó que futuros estudios utilicen el aprendizaje automático para buscar anomalías en los espectros, que podrían revelar firmas químicas inusuales y biológicas desconocidas.",
      },
      {
        para3: "Una preimpresión de su artículo se publicó en arXiv y está siendo revisada para su publicación en Astrophysical Journal. Allí los especialistas explicaron, que la “vida” sigue siendo una cuestión abierta para los científicos, y sería ventajoso ampliar el alcance de nuestra búsqueda.",
      },
    ],
  },
  {
    id: 20,
    category: "DEPORTES",
    title: "Boca Unidos logró salir airoso en un partido cambiante y emotivo",
    date: "30. Septiembre 2023",
    comments: 0,
    cover: "https://pxc.cdn.ellitoral.com.ar/litoral/092023/1695000729381.webp?cw=770&ch=440&extw=jpg",
    desc: [
      {
        para1: "El equipo correntino revirtió un resultado adverso para ganarle de local a Crucero del Norte por 2 a 1. Mainero puso en ventaja al Colectivero, pero Gabi Morales, de penal, y Maximiliano Solari sobre el final anotaron para el Aurirrojo.",
      },
      {
        para2: "",
      },
      {
        para3: "",
      },
    ],
    details: [
      {
        title: "",
      },
      { para1: "Boca Unidos golpeó último y se quedó con tres valiosos  puntos al ganarle ayer de local a Crucero del Norte por 2 a 1 para afianzarse en la tercera posición de la zona 4, en un encuentro cambiante y emotivo jugado por la fecha 31 del torneo Federal “A”." },
      {
        quote: "El Aurirrojo regaló el primer tiempo, lapso en el que el Colectivero se puso adelante en el marcador frente a un equipo local que no daba pie con bola. En el complemento, Boca Unidos cambió la imagen, empató a través de un penal, jugada en la que quedó con un hombre más, pero en su afán por ir a buscar enceguecidamente el triunfo se descuidó, cometió errores defensivos en los que su rival le perdonó la vida, hasta que llegó al desequilibrio en el final. El inicio del encuentro encontró a un Crucero del Norte enchufado que salió a jugar el partido como una verdadera final frente a un Boca Unidos que estaba dormido y deambulaba por la cancha sin poder hacer tres pases seguidos. La visita avisó al minuto, con un tiro libre que Nicolás Portillo bajó de cabeza para Alejo Mainero, que fue trabado por Joaquín Livera. ",
      },
      {
        para2: "El gol del Colectivero no  tardó en llegar. Walter Figueroa envió un centro pasado que Gastón Torres devolvió de cabeza hacia el medio. El balón le quedó otra vez a Mainero, que pifió el primer remate, pero inmediatamente se rehizo para enviar el balón a la red.",
      },
      {
        para3: "Ahora el equipo correntino tendrá que ir el próximo domingo a jugar “otra final” en Salta, frente a Juventud Antoniana, que al igual que Crucero del Norte, se juega todas las fechas la permanencia, y luego quedará libre. Pero para todavía falta, por ahora es todo alegría.",
      },
    ],
  },
  {
    id: 21,
    category: "POLITICA",
    title: "Ya se debate en Diputados la modificación del impuesto a las ganancias",
    date: "18. Septiembre 2023",
    comments: 0,
    cover: "https://www.mdzol.com/u/fotografias/m/2023/9/15/f1280x720-1476969_1608644_5050.jpeg",
    desc: [
      {
        para1: "En una reunión informativa, la Comisión de Presupuesto y Hacienda, presidida por el diputado del Frente de Todos Carlos Heller, la Cámara de Diputados de la Nación comenzó a analizar el proyecto de Ley, enviado el pasado 12 de septiembre, por el cual se deja sin efecto el impuesto a las ganancias que recae sobre los ingresos del trabajo personal ejecutado en relación de dependencia, jubilaciones y pensiones y, por el otro, incorpora en la ley del mencionado gravamen, un régimen cedular dirigido, exclusivamente, a los mayores ingresos derivados de aquellas rentas.",
      },
      {
        para2: "En primer lugar, la subsecretaria de Ingresos Públicos, Claudia Balestrini, defendió el proyecto, y en ese sentido explicó: “Lo que vinimos a hacer ahora es algo superador y tiene que ver con atender a las rentas generadas en relación de dependencia (cuarta categoría)”.",
      },
      {
        para3: "",
      },
    ],
    details: [
      {
        title: "",
      },
      { para1: "Además, la funcionaria brindó detalles de la norma: “Las rentas originadas por empleadas y empleados en relación de dependencia van a empezar a tributar bajo un esquema cedular”. “Consiste en establecer un mínimo no imponible de 180 salarios mínimos, vitales y móviles. De superar ese importe el trabajador va a tributar por el excedente”, puntualizó." },
      {
        quote: "En la misma línea que su preopinante, la ministra de Trabajo, Kelly Olmos, aseguró que “el salario no es ganancia”, al tiempo que indicó que “el proyecto establece la eliminación de la cuarta categoría del impuesto a las ganancias y su reemplazo por un impuesto a los altos ingresos”.",
      },
      {
        para2: "“Este proyecto está destinado a una mejor organización del trabajo argentino para mejorar las condiciones de productividad y redistribución que nuestro país necesita”, ponderó la responsable de la cartera de Trabajo.",
      },
      {
        para3: "Por otra parte, el titular de la Administrador Federal de Ingresos Públicos (AFIP), Carlos Castagneto, afirmó que “es un proyecto que deja sin efecto el impuesto a las ganancias que recae sobre los ingresos de trabajo personal, ejecutado en relación de dependencia, jubilaciones y pensiones” y que -añadió-, además, “incorpora un régimen cedular dirigido exclusivamente a los mayores ingresos derivado de aquellas rentas”.",
      },
    ],
  },
  {
    id: 22,
    category: "POLICIALES",
    title: "Arrojaron 11 kilos de marihuana al río y fueron detenidos",
    date: "24. Noviembre 2023",
    comments: 0,
    cover: "https://pxc.cdn.ellitoral.com.ar/litoral/112023/1700825192298.webp?cw=770&ch=440&extw=jpg",
    desc: [
      {
        para1: "El operativo fue realizado por la Policía.",
      },
      {
        para2: "La Policía de Corrientes informó este viernes que secuestró  11 kilos de marihuana y detuvo a dos hombres  en la localidad de Itatí. ",
      },
      {
        para3: "El operativo se realizó el jueves cuando observaron que dos ocupantes de una canoa  arrojaron un paquete al rio Paraná a la altura de una isla de la zona.",
      },
    ],
    details: [
      {
        title: "",
      },
      { para1: "En ese momento comenzó el procedimiento y se logró detener a dos sujetos, uno de ellos de nacionalidad paraguaya, señaló la fuerza. " },
      {
        quote: "En total, se incautó  11,225 kilos.",
      },
      {
        para2: "La canoa, la droga y los dos involucrados quedaron a disposición de la Justicia. ",
      },
      {
        para3: "",
      },
    ],
  },
  {
    id: 23,
    category: "ECONOMIA",
    title: "Pagos a jubilados, pensionados, AUH y otras prestaciones del lunes 18",
    date: "18. Septiembre 2023",
    comments: 0,
    cover: "https://www.corrienteshoy.com/galeria/fotos/2023/09/04/l_1693826424.jpg",
    desc: [
      {
        para1: "La Administración Nacional de la Seguridad Social (ANSES) pagará este entre el lunes 18 de septiembre de 2023 jubilaciones, pensiones, Asignación Universal por Hijo (AUH), Asignación por Embarazo (AUE), Asignaciones Familiares, de Pago Único (Matrimonio, Nacimiento y Adopción).",
      },
      {
        para2: "",
      },
      {
        para3: "",
      },
    ],
    details: [
      {
        title: "",
      },
      { para1: "Desde este mes las jubilaciones, pensiones y asignaciones se cobrarán con el aumento del 23,29 por ciento por la Ley de Movilidad." },
      {
        quote: "En tanto, todos los jubilados y pensionados que perciben el haber mínimo recibirán la primera cuota del refuerzo de 37 mil pesos (la segunda en octubre y la tercera en noviembre por el mismo monto), por lo que ninguno de ellos cobrará menos de 124.460 pesos.",
      },
      {
        para2: "",
      },
      {
        para3: "",
      },
    ],
  },
  {
    id: 24,
    category: "SALUD",
    title: "Más de 4 mil mujeres se hicieron la prueba de parches mamarios",
    date: "18. Septiembre 2023",
    comments: 0,
    cover: "https://www.corrienteshoy.com/galeria/fotos/2023/09/18/l_1695032518.jpg",
    desc: [
      {
        para1: "Ahora, este dispositivo estará disponible en los Centros de Atención Primaria de la Salud (CAPS) de la capital. Hasta el momento entre los resultados, se mostró un 7.11% de significatividad entre el total de mujeres que se realizaron la prueba que dura 15 minutos.",
      },
      {
        para2: "",
      },
      {
        para3: "",
      },
    ],
    details: [
      {
        title: "",
      },
      { para1: "El Ministerio de Salud Pública de la Provincia de Corrientes, a cargo de Ricardo Cardozo, lleva adelante la campaña para el diagnóstico precoz del Cáncer de Mama: “Ruta de la Salud Mamaria” a partir de la colocación de PADS o Parches Mamarios. Este nuevo método de diagnóstico precoz impulsado por el gobernador Gustavo Valdés detecta alteraciones relacionadas con este problema de salud, estaba disponible hasta el momento en el interior y algunos centros de salud de capital pero ahora se comenzará a aplicar desde esta semana en los 17 CAPS de la capital provincial." },
      {
        quote: "Los PADS o Parches Mamarios son dispositivos con una innovadora tecnología, que consiste en microsensores que captan las diferencias de temperatura de las mamas, siguiendo principios físicos termodinámicos.",
      },
      {
        para2: "“Desde hace tres meses, a partir de junio se lanzó la campaña, empezamos con las pruebas de los parches mamarios en toda la provincia. Iniciamos en el interior; en algunos centros de capital y ahora en la capital en los CAPS. Tenemos más de 4 mil pruebas de parches realizados a lo largo de toda la provincia y la semana siguiente se avanzará en los CAPS de la capital. Seguramente ahí aumentará la cantidad de pacientes que se colocan”, dijo la coordinadora de la Red Provincial de Cáncer de Mama, Karina Maidana.",
      },
      {
        para3: "",
      },
    ],
  },
  {
    id: 25,
    category: "CORRIENTES",
    title: "Gendarmería halló 16 vizcachas faenadas en Curuzú Cuatiá",
    date: "18. Julio 2023",
    comments: 0,
    cover: "https://www.corrienteshoy.com/galeria/fotos/2023/09/18/l_1695044549.jpg",
    desc: [
      {
        para1: "En horas de la madrugada del sábado, una de las patrullas transitaba en cercanías al acceso a la localidad y procedieron al control físico y documentológico de un vehículo los cuales se dirigían hacia Rafaela, Santa Fe. Parte de la carne secuestrada ya había sido cocida.",
      },
      {
        para2: "",
      },
      {
        para3: "",
      },
    ],
    details: [
      {
        title: "",
      },
      { para1: "En horas de la madrugada, y en momentos que la una de las patrullas transitaba en cercanías al acceso a la localidad de Curuzú Cuatiá, procedieron al control físico y documentológico de un vehículo, ocupado por dos masculinos mayores de edad, los cuales se dirigían hacia Rafaela, Santa Fe." },
      {
        quote: "Al momento de solicitar la documentación del rodado, los uniformados pudieron ver una un arma de fuego y varias bolsas plásticas, motivando la requisa del mismo. Producto de la inspección, se hallaron una carabina, 50 municiones, 16 vizcachas faenadas y sin cuero, así como tres bolsas más que contenían carne de la misma especie pero que ya había atravesado un proceso de cocción.",
      },
      {
        para2: "Se dio intervención a la Fiscalía Rural y Ambiental de Mercedes, así como a la Policía Rural y Ambiental de Curuzú Cuatiá, procediendo al secuestro del arma de fuego y los animales faenados, siendo desnaturalizados bajo acta de estilo.",
      },
      {
        para3: "",
      },
    ],
  },
]
export const ppost = [
  {
    id: 26,
    cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/10/723fe800-d506-4eda-86ab-ba0d67cecc33.jpg',
    category: "CULTURA",
    title: "Presentaron la 14 edición del Taragüi Rock: las sorpresas para el público",
    date: "28 Oct - 2024",
    desc: [
      {
        para1: "28/10/2024 - El gobernador de Corrientes, Gustavo Valdés, y la presidenta del Instituto de Cultura, Beatriz Kunin, anunciaron la 14ª edición del Festival Taragüi Rock, que se celebrará el 9 y 10 de noviembre en el Corsódromo Nolo Alías de la Capital."
      },
      {
        para2: "Kunin expresó su entusiasmo por este festival, consolidado a nivel local, regional y nacional, y destacó novedades como un área de juegos para niños y una zona especial para personas con capacidades diferentes."
      },
      {
        para3: "El predio en Ruta 12 km 1028 tendrá dos escenarios, capacidad para 12,000 personas, 1,900 lugares de estacionamiento y accesos para la prensa y artistas."
      }
    ],
    details: [
      {
        title: "Transporte gratuito y entradas"
      },
      {
        para1: "Se coordinó un servicio gratuito de colectivos, posiblemente desde el Parque Mitre, para facilitar el traslado de asistentes al Corsódromo. Las entradas se venden con un 20% de descuento online y posibilidad de pago en tres cuotas con tarjetas del Banco de Corrientes."
      },
      {
        title: "Declaraciones de autoridades"
      },
      {
        para2: "El gobernador Valdés destacó el valor del festival para la cultura correntina, señalando que permite la interacción con otros géneros como el chamamé. La conferencia contó con la presencia del vicegobernador Pedro Braillard Poccard y varios funcionarios."
      }
    ]
  }
  ,
  {
    id: 27,
    cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/10/821180-gustavo-20vald-c3-a9s-na.jpg',
    category: "DEPORTES",
    title: "Finalmente, Corrientes estará en los Juegos Evita 2024",
    date: "22 Oct - 2024",
    desc: [
      {
        para1: "22/10/2024 - El gobernador Gustavo Valdés anunció que Corrientes participará en los Juegos Nacionales Evita 2024, financiando los gastos con recursos provinciales para que 350 atletas puedan representar a la provincia."
      },
      {
        para2: "Valdés destacó la importancia del deporte en el desarrollo juvenil, afirmando que «el deporte es fundamental para el desarrollo de los jóvenes»."
      }
    ],
    details: [
      {
        title: "Decisión previa de no participar"
      },
      {
        para1: "El secretario de Deportes, Jorge Terrile, había informado previamente que Corrientes no participaría debido a desacuerdos con los criterios organizativos de Nación. Aclaró que la decisión no fue por falta de presupuesto, sino por diferencias en el enfoque."
      },
      {
        para2: "Terrile había expresado que la provincia ya cubría hasta el 70% de los gastos para asistir y no consideraba justo que los ajustes afecten a los deportistas. Mencionó que solo 17 de las 24 provincias confirmaron su participación este año."
      },
      {
        title: "Detalles del evento"
      },
      {
        para3: "Los Juegos Nacionales Evita 2024 se realizarán en Mar del Plata del 4 al 9 de noviembre y contarán con competencias en 36 disciplinas."
      }
    ]
  }
  ,
  {
    id: 28,
    cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/10/05-FOTO-50-Gentileza-5.jpg',
    category: "ECONOMÍA",
    title: "Valdés llamó a superar el debate parlamentario y salir al territorio",
    date: "22 Oct - 2024",
    desc: [
      {
        para1: "22/10/2024 - Durante la apertura de la Exposición Rural de Ituzaingó, el gobernador Gustavo Valdés instó a impulsar políticas que promuevan el crecimiento económico en Corrientes, destacando la calidad del ganado bovino e invitando al sector privado a invertir en la industria."
      },
      {
        para2: "Valdés subrayó la necesidad de invertir en mejoras genéticas y en infraestructura rural para potenciar la producción ganadera y combatir la pobreza, enfatizando que el desarrollo debe trascender los debates del Congreso."
      }
    ],
    details: [
      {
        title: "Impulso a la ganadería y la industria"
      },
      {
        para1: "El Gobernador resaltó la importancia de la calidad genética del ganado correntino y expresó su expectativa de que los productores obtengan buenos precios. Corrientes cuenta con 26,000 ganaderos activos y busca mejorar la eficiencia a través del Plan Pastura."
      },
      {
        title: "Fortalecimiento de la infraestructura rural"
      },
      {
        para1: "Valdés enfatizó la necesidad de mejorar caminos rurales y apoyar a organizaciones comunitarias, destacando que el desarrollo debe orientarse hacia la producción e industrialización como vía para enfrentar la pobreza."
      },
      {
        title: "Incremento de la inversión privada"
      },
      {
        para1: "El mandatario invitó al sector privado a intensificar su participación en la industria provincial, destacando la potencialidad de Corrientes en la ganadería y el desarrollo rural. La nueva sede inaugurada fue descrita como símbolo del progreso regional."
      },
      {
        title: "Respaldo financiero y desarrollo productivo"
      },
      {
        para1: "Valdés anunció un apoyo de 3.000 millones de pesos para proyectos productivos y resaltó la importancia de la producción de búfalos en Caá Catí, con 70,000 cabezas, y el interés de China en productos bubalinos."
      },
      {
        title: "Conservación de la tradición ganadera"
      },
      {
        para1: "El Gobernador destacó la relevancia de mantener la tradición ganadera correntina, orientando la producción local hacia un crecimiento sostenido a largo plazo. La ubicación estratégica de Ituzaingó busca posicionar a Corrientes como referente en genética y producción."
      }
    ]
  }
  ,
  {
    id: 29,
    cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/10/TERESA.jpg',
    category: "POLÍTICA",
    title: "La interventora del PJ correntino criticó a Kicillof",
    date: "17 Oct - 2024",
    desc: [
      {
        para1: "17/10/2024 - La senadora bonaerense María Teresa García criticó en Radio 750 a algunos dirigentes peronistas que han «embarrado» las internas del Partido Justicialista (PJ), señalando especialmente al gobernador Axel Kicillof y al mandatario riojano Ricardo Quintela."
      },
      {
        para2: "El PJ realizará elecciones el 17 de noviembre para definir su nueva conducción, en medio de divisiones pese al llamado de unidad de Cristina Fernández de Kirchner."
      }
    ],
    details: [
      {
        title: "Críticas a Kicillof y Quintela"
      },
      {
        para1: "García, exministra de Gobierno de Kicillof, cuestionó la falta de pronunciamiento del gobernador sobre las internas del PJ. Afirmó que alguien tan cercano a Cristina debería haber expresado su posición."
      },
      {
        para2: "La senadora acusó a Quintela de entorpecer el proceso interno, destacando que, a pesar de su promesa de retirar su candidatura si Cristina se presentaba, esto no ocurrió."
      },
      {
        title: "Clima de conflicto"
      },
      {
        para3: "García advirtió sobre un ambiente de odio y enojo en el peronismo y enfatizó que el partido necesita un rumbo claro. Concluyó pidiendo reflexión a Kicillof, señalando que algunas personas de su entorno no serían la mejor influencia."
      }
    ]
  }
  
  
]
export const lifestyle = [
  {
    id: 30,
    category: "INTERIOR",
    title: "Amplio operativo para la celebración del Gauchito Gil.",
    date: "05. Enero 2024",
    comments: 0,
    cover: "https://www.diarioepoca.com/content/bucket/0/1340360w762h476c.jpg.webp",
  },
  {
    id: 31,
    category: "CORRIENTES",
    title: "Día picante en Diputados: 'Empezó mal el año', advirtió Jorge Romero",
    date: "05. Enero 2024",
    cover: "https://www.diarioepoca.com/content/bucket/6/1340346w380h179c.jpg.webp",
  },
  {
    id: 32,
    category: "CORRIENTES",
    title: "Largas filas en plena Ruta 12 para comprar una garrafa de gas a $5.000",
    date: "03. Enero 2024",
    cover: "https://www.diarioepoca.com/content/bucket/2/1340352w762h564c.jpg.webp",
  },
  {
    id: 33,
    category: "CORRIENTES",
    title: "La Provincia compró el predio de la tabacalera que cerró en Goya",
    date: "17. Septiembre 2023",
    cover: "https://www.nortecorrientes.com/content/bucket/8/369968w380h220c.jpg.webp",
  },
]
export const tpost = [
  {
    id: 34,
    title: "Radio Sudamericana",
    cover: "https://cdn.webrad.io/images/logos/radioarg-com/sudamericana-corrientes.png",
    href: "https://envivo.radiosudamericana.com/inc.popup-reproductor.php?popup=true"
  },
  {
    id: 35,
    title: "Radio Dos",
    cover: "https://cdn.webrad.io/images/logos/radioarg-com/radio-dos-corrientes.png",
    href: "https://www.radiodos.com.ar/radio.php"
  },
  {
    id: 36,
    title: "LT7",
    cover: "https://cdn.webrad.io/images/logos/radioarg-com/lt7-900-corriente.png",
    href: "https://www.lt7noticias.com/radio.php"
  },
]
export const gallery = [
  {
    cover: "../images/gallery/g1.jpg",
  },
  {
    cover: "../images/gallery/g2.jpg",
  },
  {
    cover: "../images/gallery/g3.jpg",
  },
  {
    cover: "../images/gallery/g4.jpg",
  },
  {
    cover: "../images/gallery/g5.jpg",
  },
]
export const discover = [
  {
    title: "gameñss3a22aaaaaaadasa2s",
    cover: "../images/discover/d1.jpg",
  },
  {
    title: "DEPORTES",
    cover: "../images/discover/d2.jpg",
  },
  {
    title: "humaouaaaaAaArr",
    cover: "../images/discover/d3.jpg",
  },
  {
    title: "gadgets",
    cover: "../images/discover/d4.jpg",
  },
  {
    title: "movieees",
    cover: "../images/discover/d5.jpg",
  },
  {
    title: "niten3aa2asdkik25aaaaaaassadaaaao!",
    cover: "../images/discover/d6.jpg",
  },
]

