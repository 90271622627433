import { useState, useEffect } from 'react';
import { WiThermometer, WiHumidity, WiStrongWind, WiDaySunny } from 'react-icons/wi';
import './clima.css'; // Importa tu archivo CSS personalizado

const Clima = () => {
  const [clima, setClima] = useState(null);
  const API_KEY = '7a18d636ea41da88fdb4adcdf660a30d'; // Reemplaza con tu propia API key de OpenWeatherMap
  const CITY = 'Corrientes,AR'; // Ciudad y país en formato Ciudad,Código de País

  useEffect(() => {
    // Función para obtener el clima actual
    const obtenerClima = async () => {
      try {
        const response = await fetch(
          `https://api.openweathermap.org/data/2.5/weather?q=${CITY}&appid=${API_KEY}&units=metric`
        );

        if (response.ok) {
          const data = await response.json();
          setClima(data);
        } else {
          throw new Error('No se pudo obtener el clima');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    obtenerClima();
  }, []);

  return (
    <div className="rounded-lg shadow-md weather-container">
      <h1 className="weather-title">Corrientes</h1>
      {clima ? (
        <div>
          <div className="weather-item">
            <span className='temp'><WiThermometer className="weather-icon" /></span>
            <p className="weather-text">Temperatura: {clima.main.temp}°C</p>
          </div>
          <div className="weather-item">
          <span className='sol'> <WiDaySunny className="weather-icon" /></span>
            <p className="weather-text">Descripción: {clima.weather[0].description}</p>
          </div>
          <div className="weather-item">
          <span className='temp'> <WiHumidity className="weather-icon" /></span>
            <p className="weather-text">Humedad: {clima.main.humidity}%</p>
          </div>
          <div className="weather-item">
            <WiStrongWind className="weather-icon" />
            <p className="weather-text">Velocidad del viento: {clima.wind.speed} m/s</p>
          </div>
        </div>
      ) : (
        <p>Cargando...</p>
      )}
    </div>
  );
};

export default Clima;
