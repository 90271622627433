import { useState } from "react";
import "./footer.css";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Footer = () => {
  const [setFormularioEnviado] = useState(false);
  const [enviandoFormulario, setEnviandoFormulario] = useState(false);
  const enviarEmail = (event) => {
    event.preventDefault();

    if (validarFormulario() && !enviandoFormulario) {
      setEnviandoFormulario(true);
      emailjs
        .sendForm(
          "service_2d9q5ui",
          "template_6yximn8",
          event.target,
          "d-tOcww628wULPRII"
        )
        .then((response) => {
          setEnviandoFormulario(false);

          if (response.status === 200) {
            Swal.fire({
              title: "Su mensaje ha sido enviado",
              text: "Nos contactaremos a la brevedad",
              imageUrl: "/images/ctes.png",
              customClass: {
                popup: "swal2-popup", // Clase personalizada para el fondo negro
              },
              imageAlt: "Descripción de la imagen",
            }).then(() => {
              setFormularioEnviado(true);
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            });
           
          } else {
            Swal.fire(
              "Su consulta no pudo ser enviada",
              "Intente en unos minutos",
              "error"
            );
          }
        })
        .catch(() => setEnviandoFormulario(false));

      // Actualizar estado para indicar que el formulario ya fue enviado
      setFormularioEnviado(true);
    }
  };

  const validarFormulario = () => {
    const nombre = document.getElementById("nombre").value.trim();
    const email = document.getElementById("email").value.trim();
    const telefono = document.getElementById("telefono").value.trim();
    const mensaje = document.getElementById("mensaje").value.trim();

    if (!nombre || !email || !telefono || !mensaje) {
      Swal.fire(
        "Faltan campos por completar",
        "Por favor complete todos los campos antes de enviar el formulario",
        "warning",
        "OK"
      );
      return false;
    }

    // Validar que el campo de email tenga un formato válido
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      Swal.fire(
        "Formato de correo electrónico inválido",
        "Por favor ingrese un correo electrónico válido",
        "warning",
        "OK"
      );
      return false;
    }

    return true;
  };

  return (
    <>
      <footer>
        <div className="container">
          <div className="box logo">
            <div className="logo-footer">
              {/* <img src="../images/portal/LOGO-NEGRO.png" alt="Logo" /> */}
              <h2 className="titulo-navbar"> <Link to='/'>CORRIENTES A UN CLICK</Link></h2>
            </div>
            <div className="parrafo-footer">
              <i className="fa fa-envelope"></i>
              <span> corrientesaunclick@hotmail.com</span> <br />
              <i className="fa fa-headphones"></i>
              <span> +54 3794-556699</span>
            </div>

            <section className="Publicidad-Section">
              <img
                src="/images/portal/MAPA.png"
                alt="Imagen de trabajo"
                className="mapa-footer"
              />
            </section>
          </div>

          <div className="item contact-form">
            <div className="form-container">
              <h3 className="titulo-formulario-footer">CONTACTANOS</h3>
              <form onSubmit={enviarEmail}>
                <div className="form-group">
                  <label htmlFor="nombre">Nombre</label>
                  <input type="text" id="nombre" name="user_name" />
                </div>
                <div className="form-group">
                  <label htmlFor="telefono">Teléfono</label>
                  <input type="tel" id="telefono" name="user_tel" />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Correo Electrónico</label>
                  <input type="email" id="email" name="user_email" />
                </div>
                <div className="form-group full-width">
                  <label htmlFor="mensaje">Mensaje</label>
                  <textarea
                    id="mensaje"
                    name="user_mensaje"
                    rows="4"
                  ></textarea>
                </div>
                <div className="div-boton-form">
                <button type="submit" className="boton-form">Enviar Mensaje</button>

                </div>
              </form>
            </div>
          </div>
        </div>
      </footer>
      <div className="legal">
        <div className="container flexSB">
          <p>©2023 todos los derechos reservados</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
