import React from "react"
import Header from "./components/common/header/Header"
import "./App.css"
import Homepages from "./components/home/Homepages"
import Footer from "./components/common/footer/Footer"
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import SinglePage from "./components/singlePage/SinglePage"

const App = () => {
  return (
    <>
      <Router>
        <Header />
        <Switch>
          <Route exact path='/' component={Homepages} />
          <Route path='/singlepage/:id' exact component={SinglePage} />
          {/* <Route exact path='/cultura' component={Cultura} /> */}
           {/* Ruta de redireccionamiento */}
           <Route render={() => <Redirect to="/" />} />
        </Switch>
        <Footer />
      </Router>
    </>
  )
}

export default App
