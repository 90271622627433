import React from "react"

const SocialMedia = () => {
  return (
    <>
      <section className='social'>
        <div className='socBox'>
          <i className='fab fa-facebook-f'></i>
          <span>Seguinos en Facebook</span>
        </div>
        {/* <div className='socBox'>
          <i className='fab fa-pinterest'></i>
          <span>Seguinos en Facebook</span>
        </div> */}
        <div className='socBox'>
          <i className='fab fa-x'></i>
          <span>Seguinos en X</span>
        </div>
        <div className='socBox'>
          <i className='fab fa-instagram'></i>
          <span>Seguinos en Instagram</span>
        </div>
        <div className='socBox'>
          <i className='fab fa-youtube'></i>
          <span>Subcribite a YouTube</span>
        </div>
      </section>
    </>
  )
}

export default SocialMedia
